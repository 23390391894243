import { LoginKeys } from './loginKeys';
import i18n from './i18n';
import { fireEvent, setUserId } from './amplitude';
import { logError, logInfo } from './logger';
import { Auth } from 'aws-amplify';


function handleExpiredSession() {
  return ({
    errorMessage: '',
    otp: '',
    cognitoSession: null,
    error: true,
  });
}

function handleCognitoError(error: any, cognitoSession: any, hashedEmail?: string): any {
  if ((error).code === 'NotAuthorizedException' && error.message.toLowerCase().includes(LoginKeys.invalidSession)) {
    return handleExpiredSession();
  }

  return ({
    errorMessage: '',
    error: true,
  });
}

async function signIn(email: string) {
  let data;
  try {
    data = await Auth.signIn(email);
    if (!data?.challengeParam) {
      logError(`Failed to retrieve challenge parameters: ${JSON.stringify(data)}`, { data });
      throw new Error('Challenge Parameters are undefined');
    }
    if (data.challengeParam[LoginKeys.spErrorCode] && data.challengeParam[LoginKeys.spErrorCode] !== 'undefined') {
      logError(`OTP failed to send data: ${JSON.stringify(data)}`, { data });
      return ({
        error: true,
        cognitoSession: data,
      });
    }
    setUserId(data?.challengeParam['custom:sp_userToken']);
    logInfo('OTP successfully sent', {});
    return ({
      error: false,
      cognitoSession: data,
    });
  } catch (error) {
    logInfo(`OTP failed to send error: ${JSON.stringify(error)} data: ${JSON.stringify(error)}`, { error, data });
    return handleCognitoError(error, data);
  }
}

async function verifyOtp(cognitoSession: any, email: string, otp: string) {
  let data;
  try {
    data = await Auth.sendCustomChallengeAnswer(cognitoSession, otp);
    if (data?.signInUserSession) {
      fireEvent('Scalapay express otp verified');
      return {
        jwtToken: data.signInUserSession.idToken.jwtToken,
      };
    }

    fireEvent('Scalapay express otp verification failed');
    return ({
      errorMessage: i18n.t('otp.invalid_verification_code_error'),
      cognitoSession: data,
    });
  } catch (error: any) {
    return handleCognitoError(error, data);
  }
}

export {
  signIn,
  verifyOtp,
};
