import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Typography from '@mui/material/Typography';
import OtpDisplay from './OtpDisplay';
import * as LoginAuthentication from '../../../utilities/EmailLoginAuthentication';
import fetcher from '../../../utilities/fetcher';
import { scalapayUserExperimentMessageType } from '../';
import { fireEvent } from '../../../utilities/amplitude';
import { logInfo } from '../../../utilities/logger';
import { UserData } from '../../../types/UserData';
import { PaymentType } from '../../../types/CheckoutData';
import { defaultPaymentOptions, iconSources, mapPaymentOptionsToIconSources, maskString } from '../../../utilities/magicExperiment/helpers';

import styles from '../../../styles/login.module.scss';

function handleStateUpdate(response: any, setters: any) {
  logInfo(`State update response: ${JSON.stringify(response)}`, { response });
  if (!response) return;
  if (response.jwtToken) {
    setters.setJwtToken(response.jwtToken);
    setters.setErrorMessage('');
    return;
  }
  setters.setIsLoading(false);
  if (response.errorMessage === '') {
    setters.closeModal();
  }
  if (response.cognitoSession) {
    setters.setCognitoSession(response.cognitoSession);
  }
  if (response.errorMessage) {
    setters.setErrorMessage(response.errorMessage);
  }
  if (response.otp === '') {
    setters.setOtp(response.otp);
  }
}

interface LoginProps {
  email: string;
  variant: string;
  closeModal: () => void;
  session?: any;
  isInline?: boolean;
  updateUserData?: Dispatch<SetStateAction<UserData | undefined>>;
  paymentOptions?: PaymentType[];
}

function Login({ email, variant, closeModal, isInline = false, updateUserData, paymentOptions, session }: LoginProps) {
  const [otp, setOtp] = useState('');
  const [jwtToken, setJwtToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [cognitoSession, setCognitoSession] = useState<any>(session);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const setters = {
    setCognitoSession,
    setErrorMessage,
    setOtp,
    setIsLoading,
    setJwtToken,
    closeModal,
  };

  useEffect(() => {
    logInfo(`Users email: ${maskString(email)}, experiment variant: ${variant}`, { email, variant });
  }, []);

  useEffect(() => {
    setErrorMessage('');
    if (otp.length === 6) {
      verifyOtp();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  useEffect(() => {
    if(jwtToken !== '') {
      getUserData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwtToken]);

  const getUserData = async () => {
    let userData;
    setIsLoading(true);
    if (email === process.env.REACT_APP_TEST_EMAIL) {
      userData = {
        firstName: 'firstName',
        lastName: 'lastName',
        phoneNumber: '+3912345678',
        dateOfBirth: '01/01/1980',
        country: 'IT',
        state: 'MI',
        address: 'Test Address, 1',
        postcode: '1234',
        city: 'Milano',
        email,
      }
    } else {
      userData = await fetcher(`${process.env.REACT_APP_CLIENT_API_URL}v1/express/profile/details`, { headers: { Authorization: `Bearer ${jwtToken}` } });
    }

    if (userData?.errorCode) {
      window.parent.postMessage(
        {
          type: scalapayUserExperimentMessageType.ON_CLOSE_IFRAME,
        },
        '*',
      );
      return;
    }

    if (updateUserData) {
      updateUserData(userData);
    } else {
      window.parent.postMessage(
        {
          type: scalapayUserExperimentMessageType.ON_USER_VERIFIED,
          user: userData,
        },
        '*',
      )
    }

    setIsLoading(false);
  }

  const verifyOtp = async () => {
    setIsLoading(true);
    if (email === process.env.REACT_APP_TEST_EMAIL) {
      setTimeout(() => {
        handleStateUpdate({ jwtToken: 'test'}, setters);
        setIsLoading(false);
      }, 1000);
    } else {
      fireEvent('Scalapay express otp submitted', {otp, email, url: window.location.href});
      handleStateUpdate(await LoginAuthentication.verifyOtp(cognitoSession, email, otp), setters);
    }
  };

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    setErrorMessage('');
    return verifyOtp();
  }

  return (
    <div className={classnames(styles.container, {
      [styles['container--inline']]: isInline,
    })}>
      <div className={styles.header}>
        <div className={styles.header__userDetails}>
          <Typography sx={{fontSize: '16px'}}>{email}</Typography>
          {cognitoSession?.challengeParam?.['custom:sp_phone_hash'] && <Typography sx={{fontSize: '16px', color: '#8A8A8D'}}>•••• •••• ••• {cognitoSession?.challengeParam?.['custom:sp_phone_hash'].slice(cognitoSession?.challengeParam?.['custom:sp_phone_hash'].length - 3)}</Typography>}
        </div>
      </div>
      <div className={styles.body}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '700',
            textAlign: 'center',
            mt: 0,
          }}
        >
          {t('otp_magic.heading')}
        </Typography>
        <Typography sx={{fontSize: '15px', mt: 1.5}}>{t('otp_magic.message')}</Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }} data-automation="login-form" id="passwordless-authentication-form">
          <OtpDisplay
            otp={otp}
            isLoading={isLoading}
            success={jwtToken !== ''}
            hasError={errorMessage !== ''}
            setOtp={setOtp}
          />
        </form>
        {errorMessage !== '' && <Typography sx={{fontSize: '14px', color: 'red', textAlign: 'center'}}>{errorMessage}</Typography>}
        <Typography sx={{fontSize: '14px', mt: 1, color: '#8A8A8D', textAlign: 'center'}}>{t('otp_magic.footer')}</Typography>
        <div className={styles.options}>
          <div className={styles.options__list}>
            {mapPaymentOptionsToIconSources(paymentOptions ?? defaultPaymentOptions).map((icon) => (
              <div key={icon} data-name={icon} className={styles.options__item} style={{ backgroundImage: `url(${iconSources[icon]})`}} />
            ))}
          </div>
        </div>
        {(jwtToken === '' && !isInline) && (
          <div className={styles.body__closeWrapper}>
            <Typography className={styles.body__close} onClick={closeModal}>
              {t('consent.decline')}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
