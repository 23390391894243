import React, { useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import styles from '../../../styles/login.module.scss';

interface OtpDisplayProps {
  otp: string;
  setOtp: (newValue: string) => void;
  isLoading: boolean;
  success: boolean;
}

function OtpDisplay({
  otp,
  setOtp,
  isLoading,
  success,
}: OtpDisplayProps) {

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef && inputRef.current) {
      (inputRef as any).current.focus();
    }
  }, [inputRef, isLoading]);

  if (success) {
    return (
      <div className={styles.otpSuccess}>
        <CheckCircleOutlineIcon sx={{color: '#120202', fontSize: '56px'}} />
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className={styles.otpLoader}>
        <CircularProgress sx={{color: '#D9D9D9', fontSize: '56px' }} />
      </div>
    )
  }

  return (
    <Box sx={{ position: 'relative', display: 'flex' }}>
      <Box sx={{ width: '46px', height: '56px', borderRadius: '20px', background: '#F5F5F7', mr: 1, boxSizing: 'border-box', border: otp.length >= 1 ? '2px solid #5666F0' : 'unset' }} />
      <Box sx={{ width: '46px', height: '56px', borderRadius: '20px', background: '#F5F5F7', mr: 1, boxSizing: 'border-box', border: otp.length >= 2 ? '2px solid #5666F0' : 'unset' }} />
      <Box sx={{ width: '46px', height: '56px', borderRadius: '20px', background: '#F5F5F7', mr: 1, boxSizing: 'border-box', border: otp.length >= 3 ? '2px solid #5666F0' : 'unset' }} />
      <Box sx={{ width: '46px', height: '56px', borderRadius: '20px', background: '#F5F5F7', mr: 1, boxSizing: 'border-box', border: otp.length >= 4 ? '2px solid #5666F0' : 'unset' }} />
      <Box sx={{ width: '46px', height: '56px', borderRadius: '20px', background: '#F5F5F7', mr: 1, boxSizing: 'border-box', border: otp.length >= 5 ? '2px solid #5666F0' : 'unset' }} />
      <Box sx={{ width: '46px', height: '56px', borderRadius: '20px', background: '#F5F5F7', boxSizing: 'border-box', border: otp.length >= 6 ? '2px solid #5666F0' : 'unset' }} />
      <TextField
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          fontSize: '32px',
          '& .MuiInput-root:before': { content: 'none' },
          '& .MuiInput-root:after': { content: 'none' },
        }}
        value={otp}
        variant="standard"
        fullWidth
        onChange={(e: any) => setOtp(e.target.value)}
        disabled={isLoading}
        inputRef={inputRef}
        inputProps={{
          className: `${styles.newOtpCode}`,
          maxLength: 6,
          type: 'tel',
          name: 'oneTimePassword',
          autoComplete: 'one-time-code',
        }}
        data-automation="login-otp-input"
      />
    </Box>
  );
}

export default OtpDisplay;
