import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';

import styles from "./scalapayExpress.module.scss";
import { scalapayUserExperimentMessageType } from "./Autocomplete";
import { useEffect } from "react";
import initialiseAmplitude, { fireEvent } from "../../utilities/amplitude";

export default function ScalapayExpress({ isOpen, closeModal }: any) {
  const handleClose = () => {
    window.parent && window.parent.postMessage(
      {
        type: scalapayUserExperimentMessageType.ON_CLOSE_IFRAME,
        payload: {}
      },
      '*',
    );
    window.opener && window.opener.postMessage(
      {
        type: scalapayUserExperimentMessageType.ON_CLOSE_IFRAME,
        payload: {}
      },
      '*',
    );
    closeModal();
  }

  const initialiseAmplitudeForLandingPage = async () => {
    await initialiseAmplitude();
    await fireEvent('Pay with scalapay page viewed');
  }

  useEffect(() => {
    initialiseAmplitudeForLandingPage();
    window.addEventListener("beforeunload", handleClose);
  }, []);

  return (
    <Dialog
      open={isOpen}
      sx={{
        '.MuiPaper-root': {
          margin: '0',
          borderRadius: '20px',
          backgroundColor: 'transparent',
        }
      }}
    >
      <div className={styles.expressModal}>
        <div className={styles.expressModal__close} style={{ top: '16px', right: '16px' }} onClick={handleClose}>
          <CloseIcon />
        </div>
        <div className={styles.expressModal__content}>
            <img src="/images/scalapay-logo.svg" alt="scalapay-logo" width={116} height={22} />
            <Typography fontSize='20px' fontWeight='600' maxWidth='360px' textAlign='center' mt={4} color='#272727'>Proceed to checkout and choose Scalapay as a payment method</Typography>
            <Typography fontSize='14px' fontWeight='600' maxWidth='250px' textAlign='center' my={4} color='#61666A'>You will be redirect to the Scalapay checkout to pay your order in 3.</Typography>
            <img src="/images/scalapayAtCheckout.svg" alt="scalapay-logo" width={300} height={151} />
        </div>
        <div className={styles.expressModal__action}>
          <Button variant='contained' sx={{ backgroundColor: '#5666F0', borderRadius: '100px', px: 7, py: 1.5, fontSize: '14px', ':hover': { backgroundColor: '#4552C0'} }} onClick={handleClose}>
            <Typography fontSize='14px' fontWeight='600'>Ho capito</Typography>
          </Button>
        </div>
      </div>
    </Dialog>
  )
};
