import { logDebug } from "./logger";

const fetcher = (url: string, options: any) => fetch(url, options).then((res) => {
  logDebug(`fetcher response ${JSON.stringify(res)}`, res);
  return res.json()
});

export const postFetcherOptions = (body: any) => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

export default fetcher;
