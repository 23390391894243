import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import styles from "./scalapayExpress.module.scss";
import { Auth } from "aws-amplify";
import fetcher from "../../utilities/fetcher";
import { UserData } from "../../types/UserData";
import * as LoginAuthentication from "../../utilities/PhoneLoginAuthentication";
import { LoginKeys } from "../../utilities/loginKeys";
import PhoneNumberInputDisplay from "./Login/PhoneNumberInputDisplay";
import OtpDisplay from "./Login/OtpDisplay";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { initialiseAmplifyCookies } from "../../utilities/Amplify";
import initialiseAmplitude, { fireEvent, setUserId } from "../../utilities/amplitude";

export const scalapayUserExperimentMessageType = {
  ON_CLOSE_IFRAME: 'destroy',
  DATA: 'data',
}

const postUserDataToWindow = (userDetails: UserData) => {
  const message = {
    type: scalapayUserExperimentMessageType.DATA,
    payload: {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      dateOfBirth: userDetails.dateOfBirth,
      // fiscalCode: userDetails.,
      shippingFirstname: userDetails.firstName,
      shippingLastname: userDetails.lastName,
      // shippingPhonePrefix?: 'string,
      shippingPhone: userDetails.phoneNumber,
      shippingAddressLine1: userDetails.address,
      // shippingAddressLine2?: string,
      shippingAddressCity: userDetails.city,
      shippingAddressCountry: userDetails.country,
      shippingAddressState: userDetails.state,
      shippingAddressZipCode: userDetails.postcode,
      billingFirstname: userDetails.firstName,
      billingLastname: userDetails.lastName,
      // billingPhonePrefix?: string,
      billingPhone: userDetails.phoneNumber,
      billingAddressLine1: userDetails.address,
      // billingAddressLine2?: string,
      billingAddressCity: userDetails.city,
      billingAddressCountry: userDetails.country,
      billingAddressState: userDetails.state,
      billingAddressZipCode: userDetails.postcode
    }
  };
  window.parent && window.parent.postMessage(
    message,
    '*',
  );
  window.opener && window.opener.postMessage(
    message,
    '*',
  );
}

const getUserData = async (userSession: any) => {
  return await fetcher(`${process.env.REACT_APP_CLIENT_API_URL}v1/express/profile/details`, { headers: { Authorization: `Bearer ${userSession.idToken.jwtToken}` } });
}

const getUserDataAndPostToWindow = async (userSession: any) => {
  const userData = await getUserData(userSession);
  await fireEvent('Scalapay express data retrieved');
  postUserDataToWindow(userData);
  console.log(userData);
}

export default function Autocomplete({ isOpen, closeModal }: any) {
  const [userSession, setUserSession] = useState<any>(undefined);
  const [countryCode, setCountryCode] = useState('+39');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const setters = {
    setUserSession,
    setErrorMessage,
    setOtp,
    setIsLoading,
    setSuccess,
  };

  async function handleStateUpdate (response: any, setters: any) {
    if (!response) return;
    if (response.isNewUser) {
      navigate('/paywithscalapay');
      return;
    }
    if (response.signInUserSession) {
      setters.setIsLoading(true);
      await getUserDataAndPostToWindow(response.cognitoSession);
      setters.setSuccess(true);
      setters.setIsLoading(false);
      return;
    }
    setters.setIsLoading(false);
    if (Object.keys(response).includes('cognitoSession')) {
      setters.setUserSession(response.cognitoSession);
    }
    if (response.errorMessage) {
      setters.setErrorMessage(response.errorMessage);
    }
    if (response.otp === '') {
      setters.setOtp(response.otp);
    }
    if (response.toastMessage) {
      setters.setToastMessage(response.toastMessage);
    }
  }

  const getInitialUser = async () => {
    setIsLoading(true);
    initialiseAmplifyCookies();
    try {
      const currentUser = await Auth.currentSession();
      await setUserId(currentUser.getIdToken().payload['custom:sp_userToken']);
      handleStateUpdate({ signInUserSession: true, cognitoSession: currentUser }, setters);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const initialiseAmplitudeForAutocomplete = async () => {
    await initialiseAmplitude();
    await fireEvent('Scalapay express popup viewed');
  }

  let isRedirecting = false;

  useEffect(() => {
    initialiseAmplitudeForAutocomplete();
    getInitialUser();
    window.addEventListener("beforeunload", () => {
      isRedirecting = true;
      handleClose()
      isRedirecting = false;
    })
  }, []);

  useEffect(() => {
    if (success) {
      closeModal();
    }
  }, [success])

  const signIn = async (signInCountryCode = countryCode, signInPhoneNumber = phoneNumber) => {
    setIsLoading(true);
    await handleStateUpdate(await LoginAuthentication.signIn(signInCountryCode, signInPhoneNumber), setters);
    setIsLoading(false);
  };

  const verifyOtp = async () => {
    setIsLoading(true);
    const response = await LoginAuthentication.verifyOtp(userSession, countryCode, phoneNumber, otp);
    handleStateUpdate(response, setters);
  };


  const resendCode = async () => {
    setIsLoading(true);
    setErrorMessage('');
    handleStateUpdate(await LoginAuthentication.resendCode(userSession, countryCode, phoneNumber), setters);
    setIsLoading(false);
  };

  const showPhoneNumberInput = () => !userSession || (userSession.challengeParam && userSession.challengeParam[LoginKeys.spErrorCode]);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    fireEvent('Login continue button clicked', {});
    setErrorMessage('');
    if (!showPhoneNumberInput()) {
      return verifyOtp();
    }
    return signIn();
  }

  const handleClose = () => {
    if (!success && !isRedirecting) {
      window.parent && window.parent.postMessage(
        {
          type: scalapayUserExperimentMessageType.ON_CLOSE_IFRAME,
          payload: {}
        },
        '*',
      );
      window.opener && window.opener.postMessage(
        {
          type: scalapayUserExperimentMessageType.ON_CLOSE_IFRAME,
          payload: {}
        },
        '*',
      );
    }
    closeModal();
  }

  const errorMessageDisplay = (errorMessage: string | undefined) => {
    if (errorMessage && errorMessage !== '') {
      // eslint-disable-next-line react/no-danger
      return <div className={styles.alert__danger} id="passwordless-authentication-error-alert" data-automation="login-error-message" dangerouslySetInnerHTML={{ __html: errorMessage }} />;
    }
    return <></>;
  };

  useEffect(() => {
    if (otp.length === 6) {
      verifyOtp();
    }
  }, [otp]);

  return (
    <Dialog
      open={isOpen}
      sx={{
        '.MuiPaper-root': {
          margin: '0',
          borderRadius: '20px',
          backgroundColor: 'transparent',
        }
      }}
    >
      <div className={styles.expressModal}>
        <div className={styles.expressModal__container}>
          <div className={styles.expressModal__close} onClick={handleClose}>
            <CloseIcon />
          </div>
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <img src="/images/scalapay-logo.svg" alt="scalapay-logo" width={116} height={22} />
          </Box>
          {showPhoneNumberInput() ? (
            <>
              <AccountCircleOutlinedIcon sx={{ fontSize: '42px', ml: 1 }} />
              <Typography fontSize={30} fontWeight={600}>Accedi o crea un account</Typography>
              <Typography fontSize={14} fontWeight={600}>Inserisci il tuo numero di telefono per cominciare</Typography>
            </>
          ) : (
            <>
              <LockOutlinedIcon sx={{ fontSize: '42px', ml: 1 }} />
              <Typography fontSize={30} fontWeight={600}>Inserisci il codice OTP</Typography>
              <Typography fontSize={14} fontWeight={600}>Un codice OTP è stato inviato al {`${countryCode}${phoneNumber}`}</Typography>
              <Typography fontSize={14} fontWeight={600}>Dovresti riceverlo in 20s.</Typography>
            </>
          )}
          <div className={styles.expressModal__content}>
            {errorMessageDisplay(errorMessage)}
            <form onSubmit={handleSubmit} style={{ width: '100%' }} data-automation="login-form" id="passwordless-authentication-form">
              {showPhoneNumberInput() ? (
                <>
                  <PhoneNumberInputDisplay
                    cognitoSession={userSession}
                    // countryName={countryName}
                    countryCode={countryCode}
                    phoneNumber={phoneNumber}
                    isLoading={isLoading}
                    // setOpenSelectCountry={setOpenSelectCountry}
                    // sendCodeToEmail={sendCodeToEmail}
                    setPhoneNumber={setPhoneNumber} />
                </>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <OtpDisplay
                    // cognitoSession={userSession}
                    // countryCode={countryCode}
                    otp={otp}
                    // phoneNumber={phoneNumber}
                    isLoading={isLoading}
                    success={success}
                    // resendCode={resendCode}
                    // sendCodeToEmail={sendCodeToEmail}
                    // setCognitoSession={setUserSession}
                    setOtp={setOtp} />
                  <Typography sx={{ mt: 2, textDecoration: 'underline', width: '316px' }}><span className={styles.link} onClick={() => setUserSession(undefined)}>Invialo ad un altro numero</span></Typography>
                </Box>
              )}
            </form>
          </div>
          {showPhoneNumberInput() ? (
            <div className={styles.expressModal__action}>
              <Button variant='contained' sx={{ backgroundColor: '#5666F0', borderRadius: '100px', px: 7, py: 1.5, fontSize: '14px', ':hover': { backgroundColor: '#4552C0'} }} disabled={isLoading} onClick={handleSubmit}>
                <Typography fontSize='14px' fontWeight='600'>{t('consent.continue')}</Typography>
              </Button>
            </div>
          ) : (<Typography className={styles.link} fontSize='14px' fontWeight='600' color='#5666F0' textAlign='center' sx={{ textDecoration: 'underline', mt: 7 }} onClick={resendCode}>Invialo di nuovo</Typography>)}
          <Typography className={styles.terms} fontSize='10px' fontWeight='500' textAlign='center' >
            {t('passwordless.terms_and_conditions_1')}
            {' '}
            <a className={styles.link} id="terms-and-conditions-link" href={'https://cdn.scalapay.com/terms-and-conditions/it-IT/Scalapay - Terms_and_Conditions.pdf'} target="blank">{t('passwordless.terms_and_conditions_2')}</a>
            {' '}
            {t('passwordless.terms_and_conditions_3')}
            {' '}
            <a className={styles.link} id="terms-and-conditions-link" href={t('passwordless.terms_and_conditions_privacy_url')} target="blank">{t('passwordless.terms_and_conditions_4')}</a>
          </Typography>
        </div>
        <div className={styles.pinkBall} />
      </div>
    </Dialog>
  )
};
