import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../../styles/footer.module.scss';
import { MerchantInfo } from '../../types/CheckoutData';

type Props = {
  merchant: MerchantInfo;
}

const Footer = ({ merchant }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <span>&copy;{` ${new Date().getFullYear()} ${merchant.name}`}</span>
      <div className={styles.footer__links}>
        <a href={merchant.url} target="_blank" rel="noreferrer">{t('checkout.terms')}</a>
        <a href={merchant.url} target="_blank" rel="noreferrer">{t('checkout.privacy')}</a>
      </div>
    </div>
  );
};

export default Footer;
