import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { useEffect, useState } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import * as FullStory from '@fullstory/browser';
import './App.css';
import { initialiseAmplify, initialiseAmplifyCookies } from './utilities/Amplify';
import MuiTheme from './styles/MuiConfig';
import MagicExperiment from "./components/MagicExperiment";
import ScalapayExpressLandingPage from "./components/ScalapayExpress/LandingPage";
import ScalapayExpressAutocomplete from "./components/ScalapayExpress/Autocomplete";
import MerchantUserExperiment from "./components/MerchantUserExperiment";
import AutoSignIn from './components/AutoSignin/AutoSignin';
import AutoSignOut from './components/AutoSignOut/AutoSignOut';

if (!window.location.pathname.includes('autosignin') || !window.location.pathname.includes('autosignout')) {
  FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ID || 'o-1CJA94-na1', recordCrossDomainIFrames: true });
}

function App() {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (window.location.pathname.includes('autocomplete') || window.location.pathname.includes('autosignin') || window.location.pathname.includes('autosignout')) {
      initialiseAmplifyCookies();
    } else {
      initialiseAmplify();
    }
  }, [])

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MagicExperiment isOpen={isOpen} closeModal={() => setIsOpen(false)} />,
    },
    {
      path: "/paywithscalapay",
      element: <ScalapayExpressLandingPage isOpen={isOpen} closeModal={() => setIsOpen(false)} />,
    },
    {
      path: "/autocomplete",
      element: <ScalapayExpressAutocomplete isOpen={isOpen} closeModal={() => setIsOpen(false)} />,
    },
    {
      path: "/autosignin",
      element: <AutoSignIn />,
    },
    {
      path: "/autosignout",
      element: <AutoSignOut />,
    },
    {
      path: "/merchantuserexperiment",
      element: <MerchantUserExperiment isOpen={isOpen} closeModal={() => setIsOpen(false)} />,
    },
  ]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MuiTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
