import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Typography from '@mui/material/Typography';
import OtpDisplay from './OtpDisplay';
import { merchantUserExperimentMessageType } from '../';
import { fireEvent } from '../../../utilities/amplitude';
import { logInfo } from '../../../utilities/logger';
import { PaymentType } from '../../../types/CheckoutData';
import { defaultPaymentOptions, iconSources, mapPaymentOptionsToIconSources, maskString } from '../../../utilities/magicExperiment/helpers';

import styles from '../../../styles/login.module.scss';

interface LoginProps {
  email: string;
  variant: string;
  last3: string;
  url: string;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  closeModal: () => void;
  paymentOptions?: PaymentType[];
}

function Login({ email, variant, last3, url, errorMessage, setErrorMessage, isLoading, setIsLoading, closeModal, paymentOptions }: LoginProps) {
  const [otp, setOtp] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    logInfo(`Users email: ${maskString(email)}, experiment variant: ${variant}`, { email, variant });
  }, []);

  useEffect(() => {
    setErrorMessage('');
    if (otp.length === 6) {
      verifyOtp();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const verifyOtp = () => {
    setIsLoading(true);
    fireEvent('Scalapay express otp submitted', {otp, email, url});
    window.parent.postMessage(
      {
        type: merchantUserExperimentMessageType.ON_OTP_SUBMITTED_MERCHANT_USER_EXPERIMENT,
        otp,
        email,
      },
      '*',
    );
  };

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    setErrorMessage('');
    return verifyOtp();
  }

  return (
    <div className={classnames(styles.container, {
      [styles['container--inline']]: true,
    })}>
      <div className={styles.header}>
        <div className={styles.header__userDetails}>
          <Typography sx={{fontSize: '16px'}}>{email}</Typography>
          {last3 && <Typography sx={{fontSize: '16px', color: '#8A8A8D'}}>•••• •••• ••• {last3}</Typography>}
        </div>
      </div>
      <div className={styles.body}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '700',
            textAlign: 'center',
            mt: 0,
          }}
        >
          {t('otp_magic.heading')}
        </Typography>
        <Typography sx={{fontSize: '15px', mt: 1.5}}>{t('otp_magic.message')}</Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }} data-automation="login-form" id="passwordless-authentication-form">
          <OtpDisplay
            otp={otp}
            isLoading={isLoading}
            hasError={errorMessage !== ''}
            setOtp={setOtp}
          />
        </form>
        {errorMessage !== '' && <Typography sx={{fontSize: '14px', color: 'red', textAlign: 'center'}}>{errorMessage}</Typography>}
        <Typography sx={{fontSize: '14px', mt: 1, color: '#8A8A8D', textAlign: 'center'}}>{t('otp_magic.footer')}</Typography>
        <div className={styles.options}>
          <div className={styles.options__list}>
            {mapPaymentOptionsToIconSources(paymentOptions ?? defaultPaymentOptions).map((icon) => (
              <div key={icon} data-name={icon} className={styles.options__item} style={{ backgroundImage: `url(${iconSources[icon]})`}} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
