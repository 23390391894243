import { PaymentType } from "../../types/CheckoutData";

export const iconSources: Record<string, string> = {
  visa: '/images/visa.svg',
  mastercard: '/images/mastercard.svg',
  amex: '/images/amex.svg',
  paypal: '/images/paypal.svg',
  scalapay: '/images/scalapay.svg',
  klarna: '/images/klarna.svg',
  satispay: '/images/satispay.png',
  sepa: '/images/sepa.png',
  bankTransfer: '/images/banktransfer.png',
  cashOnDelivery: '/images/cod.png',
  googlepay: '/images/googlepay.png',
  applepay: '/images/applepay.png',
  sofort: '/images/sofort.png',
  amazonpay: '/images/amazonpay.png',
};

export const defaultPaymentOptions: PaymentType[] = ['paypal', 'credit card', 'scalapay'];

const badgesOrder = ['paypal', 'visa', 'mastercard', 'amex', 'scalapay', 'klarna', 'amazonpay', 'applepay', 'googlepay', 'satispay', 'sepa', 'sofort', 'bankTransfer', 'cashOnDelivery'];

export const mapPaymentOptionsToIconSources = (paymentOptions: PaymentType[]): string[] => {
  const icons: string[] = [];

  paymentOptions.forEach((payment) => {
    switch (payment) {
      case 'credit card':
        icons.push('visa', 'mastercard', 'amex');
        break;
      case 'cash on delivery':
        icons.push('cashOnDelivery');
        break;
      case 'bank transfer':
        icons.push('bankTransfer');
        break;
      default:
        icons.push(payment);
        break;
    }
  });

  const orderedIcons: string[] = [];
  badgesOrder.forEach((badge) => {
    if (icons.includes(badge)) {
      orderedIcons.push(badge)
    }
  });

  return orderedIcons;
}

export const maskString = (value: any): string => {
  if (typeof value !== 'string') {
    return '';
  }

  return `${value.slice(0, -5)}*****`;
}

export const postMessageToParent = (message: Record<string, any>) => {
  window.parent && window.parent.postMessage(
    message,
    '*',
  );
  window.opener && window.opener.postMessage(
    message,
    '*',
  );
};
