import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CheckoutData, PaymentType } from '../../types/CheckoutData';
import Radio from '../base/Radio';
import { iconSources } from '../../utilities/magicExperiment/helpers';

import styles from '../../styles/payments.module.scss';

type Props = {
  checkoutData: CheckoutData;
  selectedPayment: PaymentType;
  updateSelectedPayment: React.Dispatch<React.SetStateAction<PaymentType>>;
  accentColor: string;
  className?: string;
}

const Payment = ({ type }: { type: PaymentType }) => {
  const { t } = useTranslation();
  switch (type) {
    case 'credit card':
      return (
        <div className={styles.paymentInner}>
          <span>{t("checkout.credit_card")}</span>
          <div className={styles.paymentIcons}>
            <div className={classnames(styles.paymentIcon, styles.visa)} style={{ backgroundImage: `url(${iconSources.visa})`}} />
            <div className={styles.paymentIcon} style={{ backgroundImage: `url(${iconSources.mastercard})`}} />
            <div className={styles.paymentIcon} style={{ backgroundImage: `url(${iconSources.amex})`}} />
          </div>
        </div>
      )
    case 'paypal':
      return (
        <div className={styles.paymentInner}>
          <span>{t("checkout.paypal")}</span>
          <div className={styles.paymentIcons}>
            <div className={styles.paymentIcon} style={{ backgroundImage: `url(${iconSources.paypal})`}} />
          </div>
        </div>
      );
    case 'scalapay':
      return (
        <div className={styles.paymentInner}>
          <span>{t("checkout.scalapay")}</span>
          <div className={styles.paymentIcons}>
            <div className={styles.paymentIcon} style={{ backgroundImage: `url(${iconSources.scalapay})`}} />
          </div>
        </div>
      );
    case 'klarna':
      return (
        <div className={styles.paymentInner}>
          <span>{t("checkout.klarna")}</span>
          <div className={styles.paymentIcons}>
            <div className={styles.paymentIcon} style={{ backgroundImage: `url(${iconSources.klarna})`}} />
          </div>
        </div>
      );
    case 'satispay':
      return (
        <div className={styles.paymentInner}>
          <span>{t("checkout.satispay")}</span>
          <div className={styles.paymentIcons}>
            <div className={styles.paymentIcon} style={{ backgroundImage: `url(${iconSources.satispay})`}} />
          </div>
        </div>
      );
    case 'sepa':
      return (
        <div className={styles.paymentInner}>
          <span>{t("checkout.sepa")}</span>
          <div className={styles.paymentIcons}>
            <div className={classnames(styles.paymentIcon, styles['paymentIcon--sepa'])} style={{ backgroundImage: `url(${iconSources.sepa})`}} />
          </div>
        </div>
      );
    case 'amazonpay':
      return (
        <div className={styles.paymentInner}>
          <span>{t("checkout.amazonpay")}</span>
          <div className={styles.paymentIcons}>
            <div className={classnames(styles.paymentIcon, styles['paymentIcon--amazonpay'])} style={{ backgroundImage: `url(${iconSources.amazonpay})`}} />
          </div>
        </div>
      );
    case 'googlepay':
      return (
        <div className={styles.paymentInner}>
          <span>{t("checkout.googlepay")}</span>
          <div className={styles.paymentIcons}>
            <div className={classnames(styles.paymentIcon, styles['paymentIcon--googlepay'])} style={{ backgroundImage: `url(${iconSources.googlepay})`}} />
          </div>
        </div>
      );
    case 'applepay':
      return (
        <div className={styles.paymentInner}>
          <span>{t("checkout.applepay")}</span>
          <div className={styles.paymentIcons}>
            <div className={classnames(styles.paymentIcon, styles['paymentIcon--applepay'])} style={{ backgroundImage: `url(${iconSources.applepay})`}} />
          </div>
        </div>
      );
    case 'sofort':
      return (
        <div className={styles.paymentInner}>
          <span>{t("checkout.sofort")}</span>
          <div className={styles.paymentIcons}>
            <div className={classnames(styles.paymentIcon, styles['paymentIcon--sofort'])} style={{ backgroundImage: `url(${iconSources.sofort})`}} />
          </div>
        </div>
      );
    case 'cash on delivery':
      return (
        <div className={styles.paymentInner}>
          <span>{t("checkout.cash_on_delivery")}</span>
          <div className={styles.paymentIcons}>
            <div className={styles.paymentIcon} style={{ backgroundImage: `url(${iconSources.cashOnDelivery})`}} />
          </div>
        </div>
      );
    case 'bank transfer':
      return (
        <div className={styles.paymentInner}>
          <span>{t("checkout.bank_transfer")}</span>
          <div className={styles.paymentIcons}>
            <div className={classnames(styles.paymentIcon, styles['paymentIcon--bankTransfer'])} style={{ backgroundImage: `url(${iconSources.bankTransfer})`}} />
          </div>
        </div>
      );
    default:
      return null;
  }  
};

const Payments = ({ checkoutData, selectedPayment, updateSelectedPayment, accentColor, className }: Props) => {
  const { t } = useTranslation();
  const { payments } = checkoutData;

  return (
    <div className={classnames(className, styles.wrapper)}>
      <div className={styles.heading}>
        <p className={styles.title}>{t("checkout.payment_title")}</p>
        <p className={styles.label}>
          <span className={styles.lock} style={{ backgroundImage: 'url(/images/lock.svg)'}}></span>{t("checkout.payment_label")}
        </p>
      </div>
      <div className={styles.payments}>
        {payments.map((payment, index) => (
          <div key={index} className={styles.payment}>
            <Radio
              className={styles.payment}
              key={payment}
              selected={selectedPayment === payment}
              accentColor={accentColor}
              onClick={() => updateSelectedPayment(payment)}
            >
              <Payment type={payment} />
            </Radio>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Payments;