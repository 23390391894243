import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import i18n from '../../utilities/i18n';
import initialiseAmplitude, { fireEvent, setDeviceId } from '../../utilities/amplitude';
import Checkout from '../Checkout/Checkout';
import Login from './Login';
import { UserData } from '../../types/UserData';
import { CheckoutData } from '../../types/CheckoutData';
import { maskString } from '../../utilities/magicExperiment/helpers';
import '../../App.css';

export const merchantUserExperimentMessageType = {
  ON_LOAD_IFRAME: 'scalapay-user-experiment-on-load',
  ON_LOAD_IFRAME_MERCHANT_USER_EXPERIMENT: 'merchant-user-experiment-on-load',
  ON_OTP_SUBMITTED_MERCHANT_USER_EXPERIMENT: 'merchant-user-experiment-on-otp-submitted',
  ON_OTP_VERIFIED_MERCHANT_USER_EXPERIMENT: 'merchant-user-experiment-on-otp-verified',
  ON_INITIALISE_IFRAME_MERCHANT_USER_EXPERIMENT: 'merchant-user-experiment-on-initialise-iframe',
  ON_INITIALISE_IFRAME: 'scalapay-user-experiment-on-initialise-iframe',
  ON_CLOSE_IFRAME: 'scalapay-user-experiment-on-close',
  ON_USER_VERIFIED: 'scalapay-user-experiment-user-verified',
  ON_USER_CHECKOUT: 'scalapay-user-experiment-user-checkout',
  ON_USER_CLOSE_CHECKOUT: 'scalapay-user-experiment-user-checkout-closed',
  ON_USER_EMAIL_CHECKED: 'scalapay-user-experiment-on-user-email-checked',
}

function MerchantUserExperiment({ isOpen, closeModal }: any) {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [variant, setVariant] = useState('');
  const [checkoutData, setCheckoutData] = useState<CheckoutData>();
  const [userData, setUserData] = useState<UserData>();
  const [last3, setLast3] = useState('');
  const [url, setUrl] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener('message', handleInitialMessage);
    initialiseAmplitude();
    i18n.changeLanguage('it-IT');
    window.parent.postMessage(
      {
        type: merchantUserExperimentMessageType.ON_LOAD_IFRAME_MERCHANT_USER_EXPERIMENT,
      },
      '*',
    );

    return (() => window.removeEventListener('message', handleInitialMessage))
  }, []);

  async function handleInitialMessage(event: MessageEvent) {
    if (event.data.type === merchantUserExperimentMessageType.ON_INITIALISE_IFRAME_MERCHANT_USER_EXPERIMENT) {
      const email = event.data?.email;
      const url = event.data?.url;
      const variant = event.data?.variant;
      const last3 = event.data?.last3;

      setUrl(url);
      setEmail(email);
      setLast3(last3);
      setDeviceId(event.data?.amplitudeDeviceId);
      setVariant(variant);
      setCheckoutData(event.data?.checkoutData);
      fireEvent('Scalapay express shown', { email: maskString(email), variant, url, last3 });
    } else if (event.data.type === merchantUserExperimentMessageType.ON_OTP_VERIFIED_MERCHANT_USER_EXPERIMENT) {
      setIsLoading(false);
      if (event.data?.verified) {
        if (event.data?.userData) {
          setUserData({
            ...event.data.userData,
          });
        } else {
          console.error('[Merchant User Experiment]: No user data provided for verified user. Event data: ', JSON.stringify(event.data));
          handleClose();
        }
      } else {
        setErrorMessage(t('otp.invalid_verification_code_error'));
      }
    }
  }

  const handleClose = () => {
    window.parent.postMessage(
      {
        type: merchantUserExperimentMessageType.ON_CLOSE_IFRAME,
      },
      '*',
    );
    closeModal();
  }

  return (
    <Dialog
      open={isOpen}
      sx={{
        '.MuiBackdrop-root': {
          backgroundColor: 'white',
        },
        '.MuiDialog-container': {
          alignItems: 'flex-start',
        },
        '.MuiPaper-root': {
          margin: '0',
          boxShadow: 'none',
          maxHeight: 'none',
        }
      }}
    >
      {email !== '' && (
        <Box
          sx={{
            width: '350px',
            maxWidth: '100%',
            mx: 'auto',
            position: 'relative',
          }}>
            {checkoutData && (
              <Checkout checkoutData={checkoutData} userData={userData} setCheckoutData={setCheckoutData} merchantUserExperiment>
                {!userData && (
                  <Login
                    email={email}
                    variant={variant}
                    last3={last3}
                    url={url}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    closeModal={handleClose}
                    paymentOptions={checkoutData.payments}
                  />
                )}
              </Checkout>
            )}
        </Box>
      )}
    </Dialog>
  );
}

export default MerchantUserExperiment;