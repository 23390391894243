import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Radio from '../base/Radio';
import { CheckoutData, ShippingOption } from '../../types/CheckoutData';
import styles from '../../styles/shippingOptions.module.scss'
import { addDecimalToString, formatCurrency } from '../../utilities/currency';
import { fireEvent } from '../../utilities/amplitude';

type Props = {
  checkoutData: CheckoutData
  setCheckoutData: Dispatch<SetStateAction<CheckoutData | undefined>>;
  shippingOptions: ShippingOption[];
  accentColor: string;
}

const ShippingOptions = ({ checkoutData, setCheckoutData, shippingOptions, accentColor }: Props) => {
  const [selectedOption, setSelectedOption] = useState<ShippingOption>(shippingOptions[0]);
  const { t } = useTranslation();

  useEffect(() => {
    const { amount } = selectedOption;
    const { subtotal } = checkoutData.orderAmount;

    const newTotal = formatCurrency(addDecimalToString(subtotal, amount));
    const newOrderAmount = {
      subtotal: formatCurrency(subtotal),
      total: newTotal,
      shipping: formatCurrency(amount),
    }

    setCheckoutData((prev) => ({
      ...prev as CheckoutData,
      orderAmount: newOrderAmount,
    }));

  }, [selectedOption]);

  const handleShippingOptionChange = (option: ShippingOption) => {
    setSelectedOption(option);
    fireEvent('Scalapay express shipping option changed', {
      ...option
    });
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{t('checkout.shipping_title')}</p>
      <div className={styles.options}>
        {shippingOptions.map((option) => (
          <div key={option.name} className={styles.option}>
            <Radio
              className={styles.payment}
              key={option.name}
              selected={option.name === selectedOption?.name}
              accentColor={accentColor}
              onClick={() => handleShippingOptionChange(option)}
            >
              <div className={styles.optionInner}>
                <span className={styles.optionName}>{option.name}</span>
                <span className={styles.optionAmount}>{option.amount === 0 ? t('checkout.shipping_free') : formatCurrency(option.amount)}</span>
              </div>
            </Radio>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShippingOptions;
