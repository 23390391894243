import { Auth } from 'aws-amplify';
import { LoginKeys } from './loginKeys';
import i18n from './i18n';
import { logInfo, logWarn } from './logger';
import { fireEvent, setUserId } from './amplitude';

// function generateCognitoUserToken() {
//   return `${Math.random().toString(36).slice(-5)}${Math.random().toString(36).slice(-10)}${Math.random().toString(36).slice(-4)}`;
// }

function getSMSErrorMessage(cognitoSession?: any) {
  return cognitoSession?.challengeParam && cognitoSession?.challengeParam[LoginKeys.spExisting] === 'true' ? i18n.t('passwordless.invalid_phone_number_error_existing') : i18n.t('passwordless.invalid_phone_number_error');
}

function getSMSLimitReachedMessage(cognitoSession: any) {
  return (cognitoSession?.challengeParam && cognitoSession?.challengeParam[LoginKeys.spExisting] === 'true') || cognitoSession?.challengeParam[LoginKeys.spEmailHash] ? i18n.t('passwordless.sms_limit_reached_existing_user') : i18n.t('passwordless.sms_limit_reached_new_user');
}

function handleExpiredSession() {
  return ({
    errorMessage: i18n.t('passwordless.expired_verification_code_error_next'),
    otp: '',
    cognitoSession: null,
  });
}

function handleCognitoError(error: any, countryCode: string, phoneNumber: string, cognitoSession: any, hashedEmail?: string, sessionId?: string): any {
  if ((error).code === 'NotAuthorizedException' && error.message.toLowerCase().includes(LoginKeys.invalidSession)) {
    return handleExpiredSession();
  }

  if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return ({
      errorMessage: '',
      cognitoSession: null,
      isNewUser: true,
    })
  }

  return ({
    errorMessage: getSMSErrorMessage(cognitoSession),
  });
}

async function signIn(countryCode: string, phoneNumber: string, sessionId?: string) {
  let data;
  try {
    data = await Auth.signIn(`${countryCode}${phoneNumber}`);
    if (data.challengeParam[LoginKeys.spErrorCode] && data.challengeParam[LoginKeys.spErrorCode] !== 'undefined') {
      logWarn(`Error sending verification code to ${countryCode}${phoneNumber} (unSanitizedPhoneNumber: ${phoneNumber}). ${data.challengeParam[LoginKeys.spErrorCode]} cognito_spc_signin_otp_failed`, { ...data.challengeParam });
      return ({
        errorMessage: getSMSLimitReachedMessage(data),
        cognitoSession: data,
      });
    }
    // istanbul ignore next
    await setUserId(data.challengeParam[LoginKeys.spUserToken]);
    await fireEvent('Otp sent');
    logInfo(`Passwordless  Mobile verification code sent ${countryCode}${phoneNumber}. cognito_spc_signin_otp_sent`, { ...data.challengeParam });
    return ({
      cognitoSession: data,
    });
  } catch (error) {
    // istanbul ignore next
    logWarn(`Error in creating new Mobile verification code ${countryCode}${phoneNumber} (unSanitizedPhoneNumber: ${phoneNumber}). ${(error as any)?.message} cognito_spc_signin_invalid_session`, { error });
    return handleCognitoError(error, countryCode, phoneNumber, data, sessionId);
  }
}

// async function signupUser(countryCode: string, phoneNumber: string, sessionId?: string) {
//   const generatedUserToken = generateCognitoUserToken();
//   logWarn(`User not found, creating new user in Cognito. cognito_spc_creating_new_user ${countryCode}${phoneNumber} ${generatedUserToken}`, { });
//   try {
//     await Auth.configure({
//       authenticationFlowType: 'USER_PASSWORD_AUTH', // 'CUSTOM_AUTH',
//       clientMetadata: {
//         sp_device: 'MOBILE', sp_value: `${countryCode}${phoneNumber}`, sp_method: 'CREATE_USER', sp_countryCode: countryCode, sp_mobileNumber: `${phoneNumber}`,
//       },
//     });

//     await Auth.signIn(generatedUserToken, Date.now().toString());
//     const thrownError = new Error(LoginKeys.invalidSession) as any;
//     thrownError.code = 'NotAuthorizedException';
//     throw thrownError;
//   } catch (err) {
//     // signIn expects a UserNotFoundException with a message USER_CREATED_NOT_AUTHORIZED which tells client
//     // that USER_CREATION is successful but denied AUTHORIZATION
//     if ((err as any).code === 'UserNotFoundException' && (err as any).message.includes('USER_CREATED_NOT_AUTHORIZED')) {
//       return signIn(order, countryCode, phoneNumber, sessionId);
//     }
//     logWarn(`Error in creating new Cognito user ${countryCode}${phoneNumber}. cognito_spc_signup_error`, { err });
//     return ({
//       errorMessage: getSMSErrorMessage(),
//     });
//   }
// }

async function verifyOtp(cognitoSession: any, countryCode: string, phoneNumber: string, otp: string) {
  let data;
  try {
    data = await Auth.sendCustomChallengeAnswer(cognitoSession, otp);
    if (data.signInUserSession) {
      logInfo(`Passwordless  Verification code verified. ${countryCode}${phoneNumber} cognito_spc_otp_verified`, { ...data.challengeParam });
      await fireEvent('Otp verified');
      return {
        signInUserSession: true,
        cognitoSession: data.signInUserSession,
      };
    }
    logWarn(`Passwordless  Invalid verification code. ${countryCode}${phoneNumber} cognito_spc_otp_unverified`, { ...data.challengeParam });
    await fireEvent('Otp failed');
    return ({
      errorMessage: i18n.t('passwordless.invalid_verification_code_error'),
      otp: '',
    });
  } catch (error: any) {
    logWarn(`Error in validating verification code ${countryCode}${phoneNumber}. ${error.message} cognito_spc_otp_invalid_session`, { error });
    return handleCognitoError(error, countryCode, phoneNumber, data);
  }
}

async function resendCode(cognitoSession: any, countryCode: string, phoneNumber: string) {
  const messageId = cognitoSession.challengeParam['custom:sp_messageId'];
  logInfo(`Passwordless  Resending verification code to ${countryCode}${phoneNumber}.`, { ...cognitoSession.challengeParam, messageId });
  Auth.configure({
    authenticationFlowType: 'CUSTOM_AUTH',
    clientMetadata: { sp_device: 'MOBILE', sp_value: messageId, sp_method: 'RESEND_SMS_CODE' },
  });
  try {
    const data = await Auth.sendCustomChallengeAnswer(cognitoSession, Date.now().toString());
    await fireEvent('Resend code clicked');
    if (data.challengeParam[LoginKeys.spErrorCode] && data.challengeParam[LoginKeys.spErrorCode] !== 'undefined') {
      logWarn(`Error resending verification code to ${countryCode}${phoneNumber} (unSanitizedPhoneNumber: ${phoneNumber}). ${data.challengeParam[LoginKeys.spErrorCode]} cognito_spc_resend_sms_otp_failed`, { ...data.challengeParam });
      return ({
        errorMessage: getSMSLimitReachedMessage(data),
      });
    }
    logInfo(`Passwordless  Resending verification code to ${countryCode}${phoneNumber} successful. cognito_spc_resend_sms_otp_sent`, { ...data.challengeParam });
    return {};
  } catch (error: any) {
    logWarn(`Error resending verification code to ${countryCode}${phoneNumber} (unSanitizedPhoneNumber: ${phoneNumber}). ${error.message} cognito_spc_resend_sms_otp_invalid_session`, { error });
    return handleCognitoError(error, countryCode, phoneNumber, cognitoSession);
  }
}

export {
  signIn,
  // signupUser,
  verifyOtp,
  resendCode,
};
