import { Amplify } from 'aws-amplify';

function initialiseAmplify() {
  Amplify.configure({
    region: 'eu-central-1',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    storage: sessionStorage,
  });
}

function initialiseAmplifyCookies() {
  Amplify.configure({
    region: 'eu-central-1',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: true,
      path: '/',
      expires: 0.04,
      sameSite: 'none',
    },
  });
}

export { initialiseAmplify, initialiseAmplifyCookies };