import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_en from '../locales/en.json';
import translation_it from '../locales/it.json';

const resources = {
  'en': {
    translation: translation_en,
  },
  'it': {
    translation: translation_it,
  },
};


i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'it-IT',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;