import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import i18n from '../../utilities/i18n';
import initialiseAmplitude, { fireEvent, setDeviceId } from '../../utilities/amplitude';
import Checkout from '../Checkout/Checkout';
import Login from './Login';
import { UserData } from '../../types/UserData';
import { CheckoutData } from '../../types/CheckoutData';
import { maskString } from '../../utilities/magicExperiment/helpers';
import { signIn } from '../../utilities/EmailLoginAuthentication';
import '../../App.css';

export const scalapayUserExperimentMessageType = {
  ON_LOAD_IFRAME: 'scalapay-user-experiment-on-load',
  ON_INITIALISE_IFRAME: 'scalapay-user-experiment-on-initialise-iframe',
  ON_CLOSE_IFRAME: 'scalapay-user-experiment-on-close',
  ON_USER_VERIFIED: 'scalapay-user-experiment-user-verified',
  ON_USER_CHECKOUT: 'scalapay-user-experiment-user-checkout',
  ON_USER_CLOSE_CHECKOUT: 'scalapay-user-experiment-user-checkout-closed',
  ON_USER_EMAIL_CHECKED: 'scalapay-user-experiment-on-user-email-checked',
}

const postShowExpressCheckoutMessage = (show: boolean, email?: string) => {
  window.parent.postMessage(
    {
      type: scalapayUserExperimentMessageType.ON_USER_EMAIL_CHECKED,
      show,
      email,
    },
    '*',
  );
}

function MagicExperiment({ isOpen, closeModal }: any) {
  const [email, setEmail] = useState('');
  const [session, setSession] = useState<any>();
  const [variant, setVariant] = useState('');
  const [checkoutData, setCheckoutData] = useState<CheckoutData>();
  const [userData, setUserData] = useState<UserData>();

  useEffect(() => {
    window.addEventListener('message', handleInitialMessage);
    initialiseAmplitude();
    i18n.changeLanguage('it-IT');
    window.parent.postMessage(
      {
        type: scalapayUserExperimentMessageType.ON_LOAD_IFRAME,
      },
      '*',
    );

    return (() => window.removeEventListener('message', handleInitialMessage))
  }, []);

  async function handleInitialMessage(event: MessageEvent) {
    if (event.data.type === scalapayUserExperimentMessageType.ON_INITIALISE_IFRAME) {
      const email = event.data?.email;
      const url = event.data?.url;

      if (!email) {
        postShowExpressCheckoutMessage(false);
        return;
      }
    
      if (email === process.env.REACT_APP_TEST_EMAIL) {
        setSession({ challengeParam: { 'custom:sp_phone_hash': '+3912345678' }});
        postShowExpressCheckoutMessage(true);
      } else {
        const { error, cognitoSession } = await signIn(email);
        if (error) {
          postShowExpressCheckoutMessage(false, email);
          return;
        }
        fireEvent('Email checked', {
          isScalapayCustomer: true,
          variant: event.data?.variant,
          url,
          email,
        });
        postShowExpressCheckoutMessage(true);
        setSession(cognitoSession);
        fireEvent('Scalapay express otp sent');
      }

      setEmail(event.data?.email);
      setDeviceId(event.data?.amplitudeDeviceId);
      setVariant(event.data?.variant);
      setCheckoutData(event.data?.checkoutData);
      fireEvent('Scalapay express shown', { email: maskString(event.data?.email), variant: event.data?.variant, url: window.location.href });
    }
  }

  const handleClose = () => {
    window.parent.postMessage(
      {
        type: scalapayUserExperimentMessageType.ON_CLOSE_IFRAME,
      },
      '*',
    );
    closeModal();
  }

  return (
    <Dialog
      open={isOpen}
      sx={{
        '.MuiBackdrop-root': {
          backgroundColor: checkoutData ? 'white' : 'transparent',
        },
        '.MuiDialog-container': {
          alignItems: checkoutData ? 'flex-start' : 'center',
        },
        '.MuiPaper-root': {
          margin: '0',
          boxShadow: 'none',
          maxHeight: 'none',
        }
      }}
    >
      {email !== '' && (
        <Box
          sx={{
            width: '350px',
            maxWidth: '100%',
            mx: 'auto',
            position: 'relative',
          }}>
            {checkoutData ? (
              <Checkout checkoutData={checkoutData} setCheckoutData={setCheckoutData} userData={userData}>
                {!userData && (
                  <Login
                    email={email}
                    variant={variant}
                    closeModal={handleClose}
                    isInline
                    updateUserData={setUserData}
                    paymentOptions={checkoutData.payments}
                    session={session}
                  />
                )}
              </Checkout>
            ) : (
              <Login
                email={email}
                variant={variant}
                closeModal={handleClose}
                session={session}
              />
            )}
        </Box>
      )}
    </Dialog>
  );
}

export default MagicExperiment;