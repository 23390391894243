import amplitude from 'amplitude-js';

const initialiseAmplitude = async () => {
  const scalapayRoutes = ['autocomplete', 'paywithscalapay']
  if (scalapayRoutes.some(route => window.location.pathname.includes(route))) {
    await amplitude.getInstance().init(process.env.REACT_APP_SCALAPAY_AMPLITUDE as string || '');
  } else {
    await amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE as string || '');
  }
}

export const fireEvent = async (eventName: string, properties?: any) => {
  await amplitude.getInstance().logEvent(eventName, properties);
}

export const setUserId = async (userToken: string) => {
  await amplitude.getInstance().setUserId(userToken);
}

export const setDeviceId = (deviceId: string) => {
  amplitude.getInstance().setDeviceId(deviceId);
}

export default initialiseAmplitude;
