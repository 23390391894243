import React from 'react';
import Typography from '@mui/material/Typography';
import { Logo as LogoType } from '../../types/CheckoutData';
import styles from '../../styles/logo.module.scss';

type Props = {
  logo: LogoType;
  accentColor: string;
  merchantName: string;
}

const Logo = ({ logo, accentColor, merchantName }: Props) => {
  return (
    <div className={styles.wrapper}>
      {logo.type === 'image' ? (
        <img className={styles.image} alt="Logo" src={logo.content} data-name={merchantName} />
      ) : (
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: '700',
            ma: 0,
            color: accentColor,
          }}
        >
          {logo.content}
        </Typography>
      )}
    </div>
  )
};

export default Logo;
