import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';
import { CheckoutData, PaymentType } from '../../types/CheckoutData';
import { UserData } from '../../types/UserData';
import UserDetails from './UserDetails';
import OrderDetails from './OrderDetails';
import Payments from './Payments';
import { scalapayUserExperimentMessageType } from '../MagicExperiment';
import Footer from './Footer';
import { fireEvent } from '../../utilities/amplitude';
import { maskString } from '../../utilities/magicExperiment/helpers';

import styles from '../../styles/checkout.module.scss';
import ShippingOptions from './ShippingOptions';

type Props = {
  checkoutData: CheckoutData;
  setCheckoutData: Dispatch<SetStateAction<CheckoutData | undefined>>;
  userData?: UserData;
  merchantUserExperiment?: boolean;
  children?: React.ReactNode;
}

export type UserAddress = {
  address: string;
  postcode: string;
  city: string;
  state: string;
  houseNumber?: string;
}

const defaultColors = {
  primary: 'black',
  text: 'white',
};

const Checkout = ({ checkoutData, setCheckoutData, userData, merchantUserExperiment = false, children}: Props) => {
  const [selectedPayment, setSelectedPayment] = useState<PaymentType>(checkoutData.payments?.[0]);
  const [newAddress, setNewAddress] = useState<UserAddress>();
  const { t } = useTranslation();
  const colors = checkoutData.colors ?? defaultColors;

  useEffect(() => {
    fireEvent('Scalapay express fake checkout shown', { email: maskString(userData?.email), url: window.location.href });
  }, []);

  const handleCheckout = () => {
    let newUserData = userData;

    if (newAddress && userData) {
      newUserData = {
        ...userData,
        ...newAddress,
        address: newAddress.address + (newAddress?.houseNumber ? ` ${newAddress?.houseNumber}`: ''),
      }
    }
    window.parent.postMessage(
      {
        type: scalapayUserExperimentMessageType.ON_USER_CHECKOUT,
        payment: selectedPayment,
        user: newUserData,
        merchantUserExperiment,
      },
      '*',
    );
  };

  const handleClose = () => {
    let newUserData = userData;

    if (newAddress && userData) {
      newUserData = {
        ...userData,
        ...newAddress,
        address: newAddress.address + (newAddress?.houseNumber ? ` ${newAddress?.houseNumber}`: ''),
      }
    }
    window.parent.postMessage(
      {
        type: scalapayUserExperimentMessageType.ON_USER_CLOSE_CHECKOUT,
        user: newUserData,
      },
      '*',
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <button className={styles.header__closeBtn}  onClick={handleClose}>
          <span className={styles.header__arrow} style={{ backgroundImage: 'url(/images/arrow-left.svg)'}} />
          {`${t('checkout.back')}`}
        </button>
      </div>
      <Logo logo={checkoutData.logo} accentColor={colors.primary} merchantName={checkoutData.merchant.name} />
      {children}
      <div className={styles.borderWrapper}>
        {userData && <UserDetails className={styles.user} userData={{ ...userData, ...newAddress }} setUserAddress={setNewAddress} accentColor={colors.primary} />}
        {userData && checkoutData?.shippingOptions && (
          <ShippingOptions checkoutData={checkoutData} setCheckoutData={setCheckoutData} shippingOptions={checkoutData.shippingOptions} accentColor={colors.primary} />
        )}
        <OrderDetails className={styles.order} checkoutData={checkoutData} />
      </div>
      {userData && (
        <>
          <div className={styles.borderWrapper}>
            <Payments className={styles.payments} checkoutData={checkoutData} selectedPayment={selectedPayment} updateSelectedPayment={setSelectedPayment} accentColor={colors.primary} />
          </div>
          <button style={{ background: colors.primary, color: colors.text }} className={styles.submit} onClick={handleCheckout}>{`${t('checkout.continue')}`}</button>
        </>
      )}
      <Footer merchant={checkoutData.merchant} />
    </div>
  );
};

export default Checkout;
