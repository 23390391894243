import classnames from 'classnames';
import React, { useRef } from 'react';
import styles from '../../styles/base/radio.module.scss';

type RadioPropsType = {
  className?: string;
  selected: boolean;
  onClick: () => void;
  children: React.ReactNode;
  accentColor: string;
  alignment?: 'top' | 'center' | 'bottom',
};

const Radio = ({
  className,
  selected,
  onClick,
  children,
  accentColor,
  alignment,
}: RadioPropsType) => {
  const radioRef = useRef(null);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.target === radioRef.current && event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <div
      className={classnames(className, styles.radio, {
        [styles.selected]: selected,
        [styles[`align-${alignment}`]]: alignment,
      })}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      ref={radioRef}
    >
      <div style={{ borderColor: accentColor }} className={styles.radioBox} />
      {children}
    </div>
  );
};

export default Radio;
