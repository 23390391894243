import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import classnames from 'classnames';
import fetcher from "../../utilities/fetcher";
import { initialiseAmplifyCookies } from "../../utilities/Amplify";

import styles from './AutoSignIn.module.scss';

const parseHashData = (hash: string) => {
  const decoded = atob(hash.substring(1));
  return JSON.parse(decoded);
};

const getUserData = async (userSession: any) => {
  return await fetcher(`${process.env.REACT_APP_CLIENT_API_URL}v1/express/profile/details`, { headers: { Authorization: `Bearer ${userSession.idToken.jwtToken}` } });
}

const getUserDataAndRedirect = async (userSession: any) => {
  const { email, dateOfBirth } = await getUserData(userSession);
  console.log('Detected signed in scalapay user: ', email);
  redirectToMagicCheckout(email, dateOfBirth);
}

const redirectToMagicCheckout = (email?: string, dob?: string) => {
  const windowHash = window.location.hash;
  if (!windowHash) {
    return;
  }
  const targetUrl = process.env.REACT_APP_MAGIC_EXPRESS_CHECKOUT_URL || 'https://checkout.dev.magiccheckout.com';
  let data;
  
  try {
    data = parseHashData(windowHash);
  } catch {
    window.location.href = `${targetUrl}${windowHash}`;
  }
  
  if (dob && email === data.email) {
    const newWindowHash = `#${btoa(JSON.stringify({
      ...data,
      dob,
    }))}`;
    window.location.href = `${targetUrl}${newWindowHash}`;
  } else {
    window.location.href = `${targetUrl}${windowHash}`;
  }
}

const getInitialData = () => {
  const windowHash = window.location.hash;
  if (!windowHash) {
    return undefined;
  }

  try {
    return parseHashData(windowHash);
  } catch {
    return undefined;
  }
}

export default function AutoSignIn() {
  const [merchantLogoUrl, setMerchantLogoUrl] = useState();

  const getSignedInUser = async () => {
    try {
      initialiseAmplifyCookies();
      const currentUser = await Auth.currentSession();
      await getUserDataAndRedirect(currentUser);
    } catch (error) {
      console.log('error: ', error);
      redirectToMagicCheckout();
    }
  }

  const setMerchantLogo = () => {
    const data = getInitialData();
    const url = data?.configs?.merchantLogoUrl;
    if (url) {
      setMerchantLogoUrl(url);
    }
  };

  useEffect(() => {
    setMerchantLogo();
    getSignedInUser();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={classnames(styles.top, !merchantLogoUrl && styles['top--center'])}>
          {merchantLogoUrl && (
            <div className={styles.logoWrapper}>
              <img className={styles.logo} alt="Logo" src={merchantLogoUrl} />
            </div>
          )}
          <div className={styles.secureText}>
            <span className={styles.lock} style={{ backgroundImage: 'url(/images/lock-alt.svg)'}}></span>
            <p>Sicuro e protetto</p>
          </div>
          <div className={styles.border} />
        </div>
        <div className={styles.bottom}>
          <div className={styles.loading}>
            <div className={styles.background} />
            <span className={styles.lock} style={{ backgroundImage: 'url(/images/lock-alt.svg)'}}></span>
          </div>
          <p>Caricamento checkout</p>
        </div>
      </div>
    </div>
  );
};
