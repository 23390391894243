import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { initialiseAmplifyCookies } from "../../utilities/Amplify";
import { postMessageToParent } from '../../utilities/magicExperiment/helpers';

export default function AutoSignOut() {
  useEffect(() => {
    window.addEventListener('message', async (event) => {
      if (event.data.type === 'scalapay-express-sign-out') {
        console.log('Scalapay express sign out event: ', event.data);
        try {
          initialiseAmplifyCookies();
          await Auth.signOut();
          postMessageToParent({
            type: 'scalapay-express-signed-out',
            success: true,
          });
        } catch (error) {
          postMessageToParent({
            type: 'scalapay-express-signed-out',
            success: false,
            error,
          });
        }
      }
    });
    postMessageToParent({
      type: 'scalapay-experss-sign-out-ready',
    });
  }, []);

  return (
    <p>Scalapay Sign Out</p>
  );
};
