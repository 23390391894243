import React, { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ITProvinces } from '../../utilities/magicExperiment/ITProvinces';
import { UserData } from '../../types/UserData';
import { fireEvent } from '../../utilities/amplitude';
import { UserAddress } from './Checkout';

import styles from '../../styles/userDetails.module.scss';

type Props = {
  userData: UserData & {houseNumber?: string};
  setUserAddress: React.Dispatch<React.SetStateAction<UserAddress | undefined>>;
  accentColor: string;
  className?: string;
}

const UserDetails = ({ userData, setUserAddress, className, accentColor }: Props) => {
  const [changeAddress, setChangeAddress] = useState(false);
  const [newAddress, setNewAddress] = useState<UserAddress>({
    address: userData.address,
    city: userData.city,
    state: userData.state,
    postcode: userData.postcode,
    houseNumber: userData?.houseNumber ?? '',
  });
  const { t } = useTranslation();
  const { email, firstName, lastName, address, city, state, postcode, country, phoneNumber, houseNumber } = userData;


  const handleChangeAddress = () => {
    fireEvent('Scalapay express change address clicked');
    setChangeAddress(true);
  };

  const handleSaveAddress = () => {
    fireEvent('Scalapay express change address saved');
    setUserAddress({
      address: newAddress.address,
      houseNumber: newAddress.houseNumber,
      city: newAddress.city,
      postcode: newAddress.postcode,
      state: newAddress.state,
    });
    setChangeAddress(false);
  };

  const handleCancelAddress = () => {
    fireEvent('Scalapay express change address cancelled');
    setNewAddress({
      address: userData.address,
      city: userData.city,
      state: userData.state,
      postcode: userData.postcode,
      houseNumber: userData?.houseNumber ?? '',
    });
    setChangeAddress(false);
  };

  const handleAddressInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setNewAddress((prevState) => ({ ...prevState as UserAddress, [name]: value }));
  };

  return (
    <div className={classnames(className, styles.wrapper)}>
      <div className={styles.item}>
        <p className={styles.label}>{t('checkout.email')}</p>
        <p className={classnames(styles.content, styles.email)}>{email}</p>
      </div>
      <div className={classnames(styles.item, styles.details)}>
        <p className={styles.label}>{t('checkout.ship_to')}</p>
        <div className={styles.content}>
          <p className={styles.name}>{`${firstName} ${lastName}`}</p>
          {phoneNumber && <p>{phoneNumber}</p>}
          <div className={styles.address}>
            {!changeAddress && (
              <div className={styles.savedAddress}>
                <div>
                  {address && <p>{address}{houseNumber ? ` ${houseNumber}` : ''}</p>}
                  <p>{city ? `${city}, ` : ''}{state ? `${state}, ` : ''}{postcode ? `${postcode}, ` : ''}{country ? country : ''}</p>
                </div>
                <button className={styles.changeAddress} onClick={handleChangeAddress} style={{color: accentColor}}>{t('checkout.change')}</button>
              </div>
            )}
          </div>
        </div>
      </div>
      {changeAddress && (
        <section className={styles.newAddress}>
          <form className={styles.form} onSubmit={handleSaveAddress}>
            <input type="text" id="address" name="address" placeholder={t('address.address1')} value={newAddress.address} onChange={handleAddressInputChange} required />
            <input type="text" id="houseNumber" name="houseNumber" placeholder={t('address.houseNumber')} value={newAddress.houseNumber} onChange={handleAddressInputChange}/>
            <input type="text" id="postcode" name="postcode" placeholder={t('address.postcode')} value={newAddress.postcode} onChange={handleAddressInputChange} required />
            <input type="text" id="city" name="city" placeholder={t('address.city')} value={newAddress.city} onChange={handleAddressInputChange} required />
            <select id="state" name="state" placeholder={t('address.state')} value={newAddress.state} onChange={handleAddressInputChange} required>
              {ITProvinces.map((province) => (
                <option key={province.code} value={province.code}>{province.name}</option>
              ))}
            </select>
            <div className={styles.actions}>
              <button className={styles.saveAddress} type="submit" style={{color: accentColor}}>{t('checkout.save')}</button>
              <button className={styles.cancelAddress} onClick={handleCancelAddress}>{t('checkout.cancel')}</button>
            </div>
          </form>
        </section>
      )}
    </div>
  )
}

export default UserDetails