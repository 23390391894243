/* eslint-disable no-unused-vars */
import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Theme {
    roundedTextField: {
      paddingTop: string;
      paddingBottom: string;
      noFocusBorderWidth: string;
      focusedBorderWidth: string;
      orderDashboard: {
        inputBorderColour: string;
        paddingLeft: string;
        paddingRight: string;
        borderRadius: string,
        height: string;
        fontSize: number;
      },
      instore: {
        inputBorderColour: string;
        borderRadius: number;
        height: number;
        fontSize: number;
        paddingLeft: string;
        paddingRight: string;
      }
    }
  }
  interface ThemeOptions {
    roundedTextField?: {
      paddingTop: string;
      paddingBottom: string;
      noFocusBorderWidth: string;
      focusedBorderWidth: string;
      orderDashboard: {
        inputBorderColour: string;
        paddingLeft: string;
        paddingRight: string;
        borderRadius: string,
        height: string;
        fontSize: number;
      },
      instore?: {
        inputBorderColour: string;
        borderRadius: string;
        height: string;
        fontSize: number;
        paddingLeft: string;
        paddingRight: string;
      }
    }
  }
}

export default createTheme({
  palette: {
    primary: {
      main: '#2c2629',
    },
    secondary: {
      main: '#fae0e6',
    },
  },
  roundedTextField: {
    paddingTop: '16.5px',
    paddingBottom: '14.5px',
    noFocusBorderWidth: '1px',
    focusedBorderWidth: '2px',
    orderDashboard: {
      inputBorderColour: '#dee2e6',
      paddingLeft: '14px',
      paddingRight: '14px',
      borderRadius: '20px',
      height: '37px',
      fontSize: 16,
    },
    instore: {
      inputBorderColour: 'rgba(44, 38, 41, 1)',
      borderRadius: '30px',
      height: '52px',
      fontSize: 16,
      paddingLeft: '30px',
      paddingRight: '0px', // 0 because the SearchIcon input adornment handles it's own padding
    },
  },
  typography: {
    fontFamily: 'Proxima Nova',
    body1: {
      fontWeight: 400,
      fontSize: '14px',
    },
    body2: {
      fontWeight: 600,
      fontSize: '14px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '36px',
    },
    h4: {
      fontWeight: 700,
      fontSize: '26px',
    },
    h5: {
      fontWeight: 700,
      fontSize: '24px',
    },
    h6: {
      fontWeight: 600,
      fontSize: '16px',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedSizeLarge: {
          borderRadius: '50px',
          boxShadow: 'none',
          fontFamily: 'Proxima Nova',
          fontSize: '14px',
          fontWeight: 600,
          margin: '0 auto',
          padding: '12px 20px',
          textTransform: 'uppercase',
        },
        containedPrimary: {
          color: '#fff',
          '&:hover': {
            color: '#fff',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        labelSmall: {
          paddingLeft: '16px',
          paddingRight: '16px',
          fontSize: '14px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& legend': {
            width: 'auto',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          '& legend': {
            width: 'auto',
          },
        },
      },
    },
  },
});
