import { datadogLogs } from '@datadog/browser-logs';

datadogLogs.init({
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN as string,
  site: process.env.REACT_APP_DD_SITE,
  service: 'scalapay-autocomplete',
  env: process.env.REACT_APP_DD_ENV || 'local',
  forwardErrorsToLogs: true,
  sampleRate: 100,
});

const logWarn = (message: string, object: object) => {
  datadogLogs.logger.warn(`WARN: ${message}`, object);
};

const logInfo = (message: string, object: object) => {
  datadogLogs.logger.info(`INFO: ${message}`, object);
};

const logDebug = (message: string, object: object) => {
  datadogLogs.logger.debug(`DEBUG: ${message}`, object);
};

const logError = (message: string, object: object) => {
  datadogLogs.logger.error(`ERROR: ${message}`, object);
};

export {
  logWarn, logInfo, logDebug, logError,
};
