import React, { useEffect, useRef } from 'react';
import OtpInput from 'react-otp-input';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import styles from '../../../styles/login.module.scss';

interface OtpDisplayProps {
  otp: string;
  setOtp: (newValue: string) => void;
  isLoading: boolean;
  success?: boolean;
  hasError: boolean;
}

function OtpDisplay({
  otp,
  setOtp,
  isLoading,
  success = false,
  hasError,
}: OtpDisplayProps) {
  useEffect(() => {
    if (otpWrapperRef.current) {
      const inputs = otpWrapperRef.current.querySelectorAll('input');
      inputs[0]?.focus();
    }
  }, [])

  const otpWrapperRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (newOtp: string) => {
    setOtp(newOtp);
  };

  const handleOtpWrapperClick = () => {
    if (hasError) {
      clearOtpInputsAndFocus();
    }
  };

  const handleOtpWrapperKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace' && hasError) {
      clearOtpInputsAndFocus();
    }
  };

  const clearOtpInputsAndFocus = () => {
    setOtp('');
    if (otpWrapperRef.current) {
      const inputs = otpWrapperRef.current.querySelectorAll('input');
      inputs[0]?.focus();
    }
  };

  if (success) {
    return (
      <div className={styles.otpSuccess}>
        <CheckCircleOutlineIcon sx={{color: '#120202', fontSize: '60px'}} />
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className={styles.otpLoader}>
        <CircularProgress size={52} sx={{color: '#D9D9D9'}} />
      </div>
    )
  }

  return (
    <div
      className={styles.otpInputsWrapper}
      ref={otpWrapperRef}
      onClick={handleOtpWrapperClick}
      onKeyDown={handleOtpWrapperKeyDown}
    >
      <OtpInput
        value={otp}
        onChange={handleInputChange}
        numInputs={6}
        containerStyle={styles.otpWrapper}
        inputStyle={styles.otpInput}
        errorStyle={styles['otpInput--error']}
        focusStyle={styles['otpInput--focus']}
        hasErrored={hasError}
        isInputNum
        shouldAutoFocus
      />
    </div>
  );
}

export default OtpDisplay;
