import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CheckoutData } from '../../types/CheckoutData';

import styles from '../../styles/orderDetails.module.scss';
import { convertCurrencyToNumber } from '../../utilities/currency';

type Props = {
  checkoutData: CheckoutData;
  className?: string;
}

const OrderDetails = ({ checkoutData, className }: Props) => {
  const { t } = useTranslation();
  const { products, orderAmount } = checkoutData;

  return (
    <div className={classnames(className, styles.wrapper)}>
      <p className={styles.title}>{t("checkout.order_summary")}</p>
      <div className={styles.products}>
        {products.map((product) => (
          <div key={product.name} className={styles.product}>
            <div className={styles.product__left}>
              {product.image && <div className={styles.product__image} style={{backgroundImage: `url(${product.image})`}} />}
              <div className={styles.product__info}>
                <p className={styles.product__name}>{product.name}</p>
                {(product.quantity || product.unitPrice) && (
                  <p className={styles.product__quantityPrice}>
                    {product.quantity && <span>{`Qty ${product.quantity}`}</span>}
                    {(product.quantity && product.unitPrice) && <span> x </span>} 
                    {product.unitPrice && <span>{product.unitPrice}</span>}
                  </p>
                )}
              </div>
            </div>
            {product.subtotal && <p className={styles.product__subtotal}>{product.subtotal}</p>}
          </div>
        ))}
      </div>
      <div className={styles.amounts}>
        {orderAmount.subtotal && (
          <div className={styles.amount}>
            <span className={styles.amount__label}>{t("checkout.subtotal")}</span>
            <span className={styles.amount__value}>{orderAmount.subtotal}</span>
          </div>
        )}
        {orderAmount?.shipping && (
          <div className={styles.amount}>
            <span className={styles.amount__label}>{t("checkout.shipping_title")}</span>
            <span className={styles.amount__value}>{convertCurrencyToNumber(orderAmount.shipping) === 0 ? t('checkout.shipping_free') : orderAmount.shipping}</span>
          </div>
        )}
        {orderAmount.total && (
          <div className={classnames(styles.amount, styles.total)}>
            <span className={styles.amount__label}>{t("checkout.total")}</span>
            <span className={styles.amount__value}>{orderAmount.total}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default OrderDetails