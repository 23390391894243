import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

interface PhoneNumberInputDisplayProps {
  cognitoSession: any;
  // countryName: string;
  countryCode: string;
  phoneNumber: string;
  isLoading: boolean;
  // setOpenSelectCountry: (newValue: boolean) => void;
  setPhoneNumber: (newValue: string) => void;
}

function PhoneNumberInputDisplay({
  cognitoSession,
  // countryName,
  countryCode,
  phoneNumber,
  isLoading,
  // setOpenSelectCountry,
  setPhoneNumber,
}: PhoneNumberInputDisplayProps) {
  const { t } = useTranslation();

  const phoneNumberChanged = (e: any) => {
    setPhoneNumber(e.target.value);
  };

  return (
    <>
      {/* <Box className={styles.countryCodeSelect__dropdown} onClick={() => setOpenSelectCountry(true)} data-automation="login-country-code-dropdown">
        <img
          src={`/images/flags/${countryName}-flag-icon.svg`}
          alt={t(`passwordless.country.${countryName}`)}
          width="20px"
          height="15px" />
        <Typography variant="h6" sx={{ ml: 1 }}>{t(`passwordless.country.${countryName}`)}</Typography>
        <ExpandMore sx={{ ml: 2, fontSize: '16px' }} />
      </Box> */}
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '56px', boxSizing: 'border-box', backgroundColor: '#F5F5F7', p: 2, mr: 1, borderRadius: '5px' }}>
          <Box sx={{ borderRadius: '50%', height: '21px', width: '21px', overflow: 'hidden', display: 'flex', justifyContent: 'center' }}>
            <img src="https://portal.scalapay.com/images/flags/italy-flag-icon.svg" height={21} />
          </Box>
          <Typography ml={1}>{countryCode}</Typography>
        </Box>
        <TextField
          sx={{
            fontSize: '16px',
            '.MuiOutlinedInput-root': {
              background: '#F5F5F7'
            },
            '.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#5666F0 !important',
            },
            '.Mui-focused.MuiFormLabel-root': {
              color: '#5666F0 !important',
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent !important',
            },
          }}
          value={phoneNumber}
          variant="outlined"
          fullWidth
          onChange={phoneNumberChanged}
          autoFocus
          disabled={isLoading}
          inputProps={{
            sx: { fontSize: '16px' },
            maxLength: 12,
            type: 'tel',
            name: 'phoneNumber',
          }}
          label={t('passwordless.mobile_number')}
          data-automation="login-phone-number-input"
        />
      </Box>
    </>
  );
}

export default PhoneNumberInputDisplay;
